import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../common/Header/Header";
import Banner from "../common/Banner/Banner";
import { MS_CONSOLE_TITLE } from "../utils/Constants";
import apiService from "../utils/apiService";
import PearsonLogo from "../../images/pearson_logo.png";
import '../../Components/common/Modal/modal.css';
import '../../Components/common/css/pw_push5.css';

const Filescan = () => {
  const msTitle = `${MS_CONSOLE_TITLE} Environment - Media Delivery Console`;
  const location = useLocation();

  const [directoryOptions, setDirectoryOptions] = useState([]);
  const [variantName, setVariantName] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalDirectories, setModalDirectories] = useState([]);
  const [selectedParentFolder, setSelectedParentFolder] = useState(null);
  const [disableCheckbox, setDisableCheckbox] = useState(true);
  const [tempSubfolderSelection, setTempSubfolderSelection] = useState({});
  const [saveMessage, setSaveMessage] = useState(""); 
  const [saveMessageRoot, setSaveMessageRoot] = useState(""); 
  const [isRootFolderDenied, setIsRootFolderDenied] = useState(false); 
  const [pendingRootFolderUpdates, setPendingRootFolderUpdates] = useState({});


  useEffect(() => {
    fetchRootDirectories();
    fetchFileScanAccess();
  }, []);

  const fetchRootDirectories = async () => {
    try {
      const response = await apiService.post("/", { dir: "/" });
      const data = response.data?.data || [];
      setDirectoryOptions(data.map(item => ({
        value: item.directory,
        label: item.directory
      })));
    } catch (error) {
      console.error("Error fetching root directories:", error);
    }
  };

  const handleRootFolderCheckboxChange = (folder, isChecked) => {
    setPendingRootFolderUpdates(prev => ({
      ...prev,
      [folder.label]: isChecked
    }));
  };

  const fetchFileScanAccess = async () => {
    try {
      const response = await apiService.get("getfilescan");
      setVariantName(response.data || []);
    } catch (error) {
      console.error("Error fetching file scan access:", error);
    }
  };

  const updateDatabase = async (folderName, isChecked, subfolders) => {
    try {
      const payload = { name: folderName, isChecked, parentFolder: folderName, subfolders };

      // Send the request to update the database
      await apiService.put("fileScanAccess", payload);
      fetchFileScanAccess(); // Refresh the file scan access
    } catch (error) {
      console.error("Error updating folder:", error);
    }
  };

  const saveRootFolderSelections = async () => {
    const updates = Object.entries(pendingRootFolderUpdates);
    for (const [folderName, isChecked] of updates) {
      try {
        await apiService.put("fileScanAccess", {
          name: folderName,
          isChecked,
          parentFolder: folderName,
          subfolders: { true: [], false: [] }
        });
      } catch (error) {
        console.error(`Error updating folder ${folderName}:`, error);
      }
    }
    fetchFileScanAccess();
    setPendingRootFolderUpdates({});
    // setSaveMessage("Root folder access updated successfully!");
    setSaveMessageRoot("Root folder access updated successfully!")
    setTimeout(() => setSaveMessageRoot(""), 3000);
  };

  const fetchModalContent = async (folder) => {
    setSelectedParentFolder(folder.value);
    try {
      const response = await apiService.post("/", { dir: folder.value });
      const dataList = response.data?.data || [];
      const data = dataList.filter(item => !item.isFile);

      const parentFolderData = variantName.find(item => item.name === folder.value) || {
        isChecked: false,
        subfolders: { true: [], false: [] }
      };

      // Format the subfolder data
      const formattedData = data.map(subfolder => {
        // Check if the subfolder has true or false in the database
        const isCheckedTrue = parentFolderData.subfolders.true.some(sub => sub.name === `${folder.value}/${subfolder.directory}`);
        const isCheckedFalse = parentFolderData.subfolders.false.some(sub => sub.name === `${folder.value}/${subfolder.directory}`);
        
        // If the root folder is checked, default subfolders to true (allow) but check against DB
        if (parentFolderData.isChecked) {
          return {
            directory: subfolder.directory,
            isCheckedTrue: isCheckedFalse ? false : true,
            isCheckedFalse: isCheckedFalse ? true : false
          };
        }

        // If root folder is unchecked, all subfolders should be deny (false)
        return {
          directory: subfolder.directory,
          isCheckedTrue: false,
          isCheckedFalse: true
        };
      });

      setModalDirectories(formattedData);
      setTempSubfolderSelection(formattedData.reduce((acc, subfolder) => {
        acc[subfolder.directory] = {
          isCheckedTrue: subfolder.isCheckedTrue,
          isCheckedFalse: subfolder.isCheckedFalse
        };
        return acc;
      }, {}));

      // Set the root folder deny status
      setIsRootFolderDenied(!parentFolderData.isChecked);

      setShowModal(true);
    } catch (error) {
      console.error("Error fetching subfolders:", error);
    }
  };

  const handleSubfolderCheckboxToggle = (subfolder, type) => {
    setTempSubfolderSelection(prev => ({
      ...prev,
      [subfolder.directory]: {
        isCheckedTrue: type === "true",
        isCheckedFalse: type === "false"
      }
    }));
  };

  const saveSubfolderSelections = async () => {
    const parentFolder = variantName.find(item => item.name === selectedParentFolder);
    if (!parentFolder) return;

    const updatedSubfolders = {
      true: Object.entries(tempSubfolderSelection)
        .filter(([_, sub]) => sub.isCheckedTrue)
        .map(([name]) => ({ name: `${selectedParentFolder}/${name}` })),
      false: Object.entries(tempSubfolderSelection)
        .filter(([_, sub]) => sub.isCheckedFalse)
        .map(([name]) => ({ name: `${selectedParentFolder}/${name}` }))
    };

    await updateDatabase(selectedParentFolder, parentFolder.isChecked, updatedSubfolders);

    // Set confirmation message (modal stays open)
    setSaveMessage("File scan access updated successfully!");
    setTimeout(() => setSaveMessage(""), 3000);
  };

  const closeModal = () => {
    setShowModal(false);
    setSaveMessage(""); // Reset the saveMessage when the modal is closed
  };

  return (
    <div>
      <Header title={msTitle} />
      <Banner title={msTitle} currentPath={location.pathname} disableCheckbox={disableCheckbox} />

      <div className="container mt-4">
        <table border="1" width="40%" cellSpacing="1" cellPadding="4">
          <thead className="thead-dark">
            <tr>
              <th>Root Folder</th>
              <th className="text-center">Allow</th>
              <th className="text-center">Deny</th>
            </tr>
          </thead>
          <tbody>
            {directoryOptions.map((folder, index) => {
              const parentFolderData = variantName.find(item => item.name === folder.label) || { isChecked: false };
              return (
                <tr key={index}>
                  <td onClick={() => fetchModalContent(folder)} style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>{folder.value}</td>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={pendingRootFolderUpdates[folder.label] ?? parentFolderData.isChecked}
                      onChange={() => handleRootFolderCheckboxChange(folder, true)}
                    />
                  </td>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={!(pendingRootFolderUpdates[folder.label] ?? parentFolderData.isChecked)}
                      onChange={() => handleRootFolderCheckboxChange(folder, false)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {Object.keys(pendingRootFolderUpdates).length > 0 && (
          <button className="mt-3" onClick={saveRootFolderSelections}>Save</button>
        )}
        {saveMessageRoot && (
          <div style={{ color: "green", fontWeight: "bold", marginTop: "10px" }}>{saveMessageRoot}</div>
        )}
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content-filescan">
            <div className="modal-header">
              <table border="0" width="100%" cellSpacing="3" cellPadding="0" className='modal-content-table'>
                <tr>
                  <td><span className="pagetitle">File scan</span></td>
                  <td align="right">
                    <img src={PearsonLogo} alt="Pearson Education" width="85" height="28" />
                  </td>
                  <td><span className="close" onClick={closeModal}>&times;</span></td>
                </tr>
              </table>
            </div>
            <div className="modal-footer" style={{ padding: "5px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              {!saveMessage && (
                <button disabled={isRootFolderDenied} onClick={saveSubfolderSelections}>Save</button>
              )}

              {saveMessage && (
                <span style={{ color: "green", fontWeight: "bold" }}>{saveMessage}</span>
              )}
            </div>
            <div className="modal-body">
              <table border="1" width="100%">
                <thead><tr><th>Subfolder Name</th><th>Allow</th><th>Deny</th></tr></thead>
                <tbody>
                  {modalDirectories.map((subfolder, index) => (
                    <tr key={index}>
                      <td>{subfolder.directory}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={tempSubfolderSelection[subfolder.directory]?.isCheckedTrue}
                          onChange={() => handleSubfolderCheckboxToggle(subfolder, "true")}
                          disabled={isRootFolderDenied} // Disable "Allow" (true) when root folder is "deny"
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          checked={tempSubfolderSelection[subfolder.directory]?.isCheckedFalse}
                          onChange={() => handleSubfolderCheckboxToggle(subfolder, "false")}
                          disabled={false} // Allow "Deny" (false) to be always editable
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filescan;
